import { ethers } from "ethers";

class EthereumWrapper {
  constructor() {
    this.provider = null;
    this.signer = null;
    this.network = null;
  }

  async connect() {
    if (window.ethereum) {
      this.provider = new ethers.BrowserProvider(window.ethereum);
      this.signer = await this.provider.getSigner();
      this.network = await this.provider.getNetwork();

    } else {
      console.error('No Ethereum provider detected. Install MetaMask or similar.');
    }
  }

  async getAccount() {
    if (this.signer) {
      const address = await this.signer.getAddress();
      return address;
    }
    return null;
  }

  async getNetwork() {
    return this.network;
  }

  async getSigner() {
    return this.signer;
  }

  // Add more Ethereum-related functions as needed
}

export default EthereumWrapper;