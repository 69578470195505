import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import GridList from '@material-ui/core/GridList';
import GridListTile from '@material-ui/core/GridListTile';
import GridListTileBar from '@material-ui/core/GridListTileBar';
import ListSubheader from '@material-ui/core/ListSubheader';
import IconButton from '@material-ui/core/IconButton';
import InfoIcon from '@material-ui/icons/Info';

const useStyles = makeStyles((theme) => ({
    root: {
      display: 'flex',
      flexWrap: 'wrap',
      justifyContent: 'space-around',
      overflow: 'hidden',
      backgroundColor: '#e9f2ff',
    },
    gridList: {
      width: 1000,
      height: 'auto', // You can set height to 'auto' for dynamic sizing.
    },
    titleBar: {
      background:
        'linear-gradient(to top, rgba(0,0,0,0.7) 0%, rgba(0,0,0,0.3) 70%, rgba(0,0,0,0) 100%)',
    },
    icon: {
      color: 'rgba(255, 255, 255, 0.54)',
    },
    img: {
      borderRadius: 20, // Add corner radius to the img element
    },
    gridItem: {
      borderRadius: 20,
    },
    iconImage: {
      width: 15,
      height: 15,
    },
    gridTitleBar: {
      borderRadius: 10,
    }
  }));

  export default function TitlebarGridList({nfts, onNftClick}) {
    const classes = useStyles();
  
    return (
      <div className={classes.root}>
        <GridList cellHeight={150}  spacing={30} className={classes.gridList} cols={4}>
          <GridListTile key="Subheader" cols={4} style={{ height: 'auto' }}>
            <ListSubheader component="div"></ListSubheader>
          </GridListTile>
          {nfts.map((nft, id) => (
            <GridListTile key={id} className={classes.gridItem} style={{ width: 300, height: 300 }}>
              <img src={nft.cached_file_url} alt={nft.name} className={classes.img} />
              <GridListTileBar
                title={nft.name}
                subtitle={"Delegate NFT"}
                actionIcon={
                  
                  <IconButton aria-label={`info about ${nft.name}`} className={classes.icon} onClick={() => onNftClick(nft)}>
                    <img src="https://delegate.xyz/images/delegatecash-logo.png" className={classes.iconImage}/>
                  </IconButton>
                }
                className={classes.gridTitleBar}
              />
            </GridListTile>
          ))}
        </GridList>
      </div>
    );
  }