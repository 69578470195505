import { useContext } from 'react';
import { MetamaskActions, MetaMaskContext } from '../hooks';
import {
  connectSnap,
  delegateNFT,
  getSnap,
} from '../utils';
import { makeStyles } from '@material-ui/core/styles';


import { useState, useEffect } from 'react';

import { ethers } from 'ethers';
import EthereumWrapper from '../EthereumWrapper';
import ButtonAppBar from '../custom_components/Header'
import TitlebarGridList from '../custom_components/Card'
import { MenuView } from '../custom_components/MenuView';


const useStyles = makeStyles((theme) => ({
  componentContainer: {
    position: "relative",
  },
  loadingOverlay: {
    position: "absolute",
    top: "0",
    left: "0",
    width: "100%",
    height: "100%",
    background: "rgba(0, 0, 0, 0.5)", /* Black background with 50% alpha */
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    zIndex: "999", /* Ensure it's above other content */
  },
  loadingLabel: {
    color: "white", /* Text color */
    fontSize: "24px", /* Adjust the font size as needed */
    fontWeight: "bold",
  },
  copyButtonContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "90vh",
  },
  connectButton: {
    backgroundColor: "#0074cc",
    color: "white",
    padding: "20px 40px",
    fontSize: "24px",
    border: "none",
    cursor: "pointer"
  },
}));


// .copy-button-container {
//   display: flex;
//   justify-content: center;
//   align-items: center;
//   height: 100vh;
//   background-color: #e9f2ff;
// }

// .big-blue-button {
  // background-color: #0074cc;
  // color: white;
  // padding: 20px 40px;
  // font-size: 24px;
  // border: none;
  // cursor: pointer;
// }

function App() {

  const classes = useStyles();

  const [ethereumWrapper, setEthereumWrapper] = useState(null);
  const [account, setAccount] = useState(null);

  // nfts
  const [nfts, setNFTs] = useState([])
  const [collections, setCollections] = useState([]);
  
  // snap
  const [state, dispatch] = useContext(MetaMaskContext);
  const [networkId, setNetworkId] = useState<unknown>();

  const [loading, setLoading] = useState(false);

  // useEffect
  useEffect(() => {

    const initEthereum = async () => {
      const wrapper = new EthereumWrapper();
      await wrapper.connect()
      setEthereumWrapper(wrapper);
    };

    initEthereum();
  }, []);

  // fetch all nfts
  const fetchNFTs = async (account) => {

    const options = {
      method: 'GET',
      headers: {
        accept: 'application/json',
        Authorization: '2c576ec9-4489-454e-bf92-0309acdc2c84'
      }
    };

    const response = await fetch(`https://api.nftport.xyz/v0/accounts/${account}?chain=ethereum&page_number=1&page_size=50&include=metadata&refresh_metadata=false`, options)
                            .catch(err => console.error(err));

    return response.json();
  };

  // fetch collections
  const fetchCollections = async (account) => {

    const options = {
      method: 'GET',
      headers: {
        accept: 'application/json',
        Authorization: '2c576ec9-4489-454e-bf92-0309acdc2c84'
      }
    };

    const response = await fetch(`https://api.nftport.xyz/v0/accounts/contracts/${account}?chain=ethereum&type=owns_contract_nfts&page_size=20`, options)
                            .catch(err => console.error(err));

    return response.json();

  };

  const fetchNFTBasedOnCollection = async (account, collection) => {
    const options = {
      method: 'GET',
      headers: {
        accept: 'application/json',
        Authorization: '2c576ec9-4489-454e-bf92-0309acdc2c84'
      }
    };

    const response = await fetch(`https://api.nftport.xyz/v0/accounts/${account}?chain=ethereum&page_size=50&include=metadata&contract_address=${collection}`, options)
                            .catch(err => console.error(err));
    return response.json();

  }

  const handleConnectClick = async () => {
    try {
      await connectSnap('npm:@s4rv4d/delegate_snap_poc');
      const installedSnap = await getSnap();

      console.log("installed snap:", installedSnap);

      dispatch({
        type: MetamaskActions.SetInstalled,
        payload: installedSnap,
      });
    } catch (e) {
      console.error(e);
      dispatch({ type: MetamaskActions.SetError, payload: e });
    }
  };

  // handle ethereum connect and set account and pass to account
  async function handleConnect() {

    if (ethereumWrapper) {
      console.log("Ethereum wrapped initd");
      // connect to walllet

      const connectWallet = async () => {
        const userAccount = await ethereumWrapper.getAccount();
        console.log(userAccount);
        setAccount(userAccount);

        handleConnectClick()
        .then(() => {

          fetchCollections(userAccount)
          .then( data => {
            console.log(data);
            setCollections(data.contracts)
          })
          .catch(err => setCollections([]));

        });
      };

      await connectWallet();
    } else {
      console.log("Ethereum wrapper not init");
    }
  }

  // handle nft tap
  const handleNftTap = async (nft) => {
    try {
      const returnData = await delegateNFT(nft);

      const contractAbi = [
        // Add the ABI definition for the delegateForToken function here
        {
          constant: false,
          inputs: [
            { name: 'delegate', type: 'address' },
            { name: 'contract_', type: 'address' },
            { name: 'tokenId', type: 'uint256' },
            { name: 'value', type: 'bool' },
          ],
          name: 'delegateForToken',
          outputs: [],
          payable: false,
          stateMutability: 'nonpayable',
          type: 'function',
        },
        // ...
      ];

      const signer = await ethereumWrapper.getSigner();

      console.log(signer);

      const delegateRegistryV1 = new ethers.Contract("0x00000000000076A84feF008CDAbe6409d2FE638B", contractAbi, signer);

      const data = delegateRegistryV1.interface.encodeFunctionData('delegateForToken', [
        returnData,
        nft.contract_address,
        nft.token_id,
        true,
      ]);
      try { 
        const [from] = (await window.ethereum.request({
          method: 'eth_requestAccounts',
        })) as string[];

        console.log(from);

        // Send a transaction to MetaMask.
        await window.ethereum.request({
          method: 'eth_sendTransaction',
          params: [
            {
              from: from,
              to: "0x00000000000076A84feF008CDAbe6409d2FE638B",
              value: '0x0',
              data: data,
            },
          ],
        })
        .then((txHash) => console.log(txHash))
        .catch((error) => console.error(error));

        console.log("not coming here why");

      } catch (e) {
        console.error(e);
      }

      // const transaction = await delegateRegistryV1.delegateForToken(returnData, nft.collectionAddress, nft.collectionTokenId, true)

      // const transactionReceipt = await transaction.wait();
      // console.log(transactionReceipt);


    } catch (e) {
      console.error(e);
      dispatch({ type: MetamaskActions.SetError, payload: e });
    }
  };

  // handle collection tap
  const handleCollectionTap = async (collection) => {

    setLoading(true);

    await fetchNFTBasedOnCollection(account, collection.address)
    .then( data => {
      setNFTs(data.nfts);
      setLoading(false);
    })
    .catch(err => {setNFTs([])
      setLoading(false);
    });
  };

  return(
  <div style={{backgroundColor: '#e9f2ff', position: "relative",}}>

    <ButtonAppBar account={account} onConnectClick={() => handleConnect()} state={state} />

    {state.installedSnap && (

      <div style={{ display: 'flex' }}>
            <div style={{ width: '250px', backgroundColor: '#e9f2ff', paddingTop: '10px', paddingLeft: '20px'}}>
              <MenuView collections={collections} onCollectionClick={handleCollectionTap} />
            </div>

            <div style={{ flex: 1 }}>
              <TitlebarGridList nfts={nfts} onNftClick={handleNftTap} />
            </div>
      </div>      
    )}

    {loading && (
      <div className={classes.loadingOverlay}>
        <div className={classes.loadingLabel}>Fetching NFTs...</div>
      </div>
    )}

    {!account && (
      <div className={classes.copyButtonContainer}>
        <button className={classes.connectButton} onClick={() => handleConnect()}>
          Connect
        </button>
      </div>
    )}

  </div>
  );
}

export default App;