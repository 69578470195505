import React from 'react';
import styled from 'styled-components';
import { makeStyles } from '@material-ui/core/styles';


const useStyles = makeStyles((theme) => ({
  iconImage: {
    width: 20,
    height: 20,
    marginRight: '10px',
    borderRadius: 20,
  },
  menuItem: {
    display: 'flex',
    alignItems: 'center',
    padding: '10px', // Spacing between items
    marginBottom: '10px', // Spacing between rows
    borderRadius: 4,
    color: 'black',
    fontWeight: 'bold',
    backgroundColor: 'ghostwhite', // Light background color
    boxShadow: '2px 2px 5px rgba(0, 0, 0, 0.3)',
    marginRight: '10px',
  }
}));

export const MenuView = ({collections, onCollectionClick}) => {

  const classes = useStyles();

  return (
    // <StyledMenu>
      
    /* </StyledMenu> */

    <div>
      {collections.map((collection, index) => (
        <div className={classes.menuItem} key={index} onClick={() => onCollectionClick(collection)}>
          <img src={collection.metadata.cached_thumbnail_url} className={classes.iconImage} alt={collection.name} />
          {collection.name}
        </div>
      ))}
    </div>

  );
};
