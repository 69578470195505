import React from "react";
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';

// styles
const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  title: {
    flexGrow: 1,
    color: "black",
    fontWeight: 'bold',
    textAlign: "leading", // Center align the title
    fontSize: '24px', // Adjust the font size as needed
  },
  toolbar: {
    backgroundColor: '#e9f2ff',
  },
  connectButton: {
    backgroundColor: '#0074cc', // Button background color
    color: 'white', // Button text color
    padding: '10px 20px', // Adjust padding as needed
    borderRadius: '4px', // 4 corner radius
    fontWeight: '500', // Medium font weight
  },
  noBoxShadow: {
    boxShadow: 'none', // Remove the shadow
  },
}));

export default function ButtonAppBar({account, onConnectClick, state}) {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <AppBar position="static" color="transparent" className={classes.noBoxShadow}>
        <Toolbar className={classes.toolbar}>

          <Typography variant="h1" className={classes.title}>
            Delegate Cash POC
          </Typography>

          {account && (
            <Button color="inherit" className={classes.connectButton} onClick={onConnectClick}>
            {account ? `${account.substr(0,4) + "..." + account.substr(account.length - 4, 4)}` : `Connect`}
          </Button>
          )}
        </Toolbar>
      </AppBar>
    </div>
  );
}